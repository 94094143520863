<template>
  <table-overview
    title="Tag Sub Types"
    store="tagSubTypes"
    :headers="[
      { text: 'Name', value: 'name' },
      { text: 'Type', value: 'type', sortable: false },
      {
        text: 'Actions',
        align: 'center',
        sortable: false,
        class: 'v-table-th--icon',
        rolesOrPermissions: 'tags_view'
      }
    ]"
    :actions="actions"
    force-initial-sort
  >
    <tag-sub-type-table-row
      slot="table-row"
      slot-scope="{ item }"
      :tag-sub-type-id="item"
    />
  </table-overview>
</template>
<script type="text/babel">
import TableOverview from "../../components/generic/overview/TableOverview";
import tagSubTypesModule from "@/store/modules/tag-sub-types";
import { mapOverviewLifecycleMethods } from "../helpers";
import TagSubTypeTableRow from "@/components/tag-sub-type/TagSubTypeTableRow";

const overviewLifecycleMethods = mapOverviewLifecycleMethods(
  "tagSubTypes",
  tagSubTypesModule
);

export default {
  name: "tag-sub-types-view",
  components: {
    TagSubTypeTableRow,
    TableOverview
  },
  data: () => ({
    actions: [
      {
        icon: "far fa-fw fa-plus",
        color: "pink",
        to: { name: "tag-sub-types.create" },
        rolesOrPermissions: "tag_sub_types_create"
      }
    ]
  }),
  ...overviewLifecycleMethods
};
</script>
