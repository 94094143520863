<template>
  <tr>
    <td>
      {{ tagSubType.name }}
    </td>
    <td>{{ typeName }}</td>
    <td>
      <v-btn
        icon
        :to="{
          name: 'tag-sub-types.show',
          params: { tagSubTypeId: tagSubType.id }
        }"
      >
        <v-icon>far fa-search</v-icon>
      </v-btn>
    </td>
  </tr>
</template>
<script type="text/babel">
import { isNil } from "lodash";
import { createNamespacedHelpers } from "vuex";
import { FIND_BY_ID } from "@/store/templates/table/getter-types";

const { mapGetters: mapAuthGetters } = createNamespacedHelpers("auth");
const {
  mapGetters: mapTagGetters,
  mapState: mapTagState
} = createNamespacedHelpers("tagSubTypes");

export default {
  name: "tag-sub-type-table-row",
  props: {
    tagSubTypeId: {
      type: Number,
      required: true
    }
  },
  computed: {
    ...mapAuthGetters(["hasRolesOrPermissions"]),
    ...mapTagGetters([FIND_BY_ID]),
    ...mapTagState(["tagType"]),
    tagSubType() {
      return this[FIND_BY_ID](this.tagSubTypeId);
    },
    typeName: function() {
      return !isNil(this.tagSubType.type)
        ? this.tagType[this.tagSubType.type].name
        : null;
    }
  }
};
</script>
