<template>
  <v-container fluid>
    <v-text-field
      v-model="name"
      v-validate="'required|max:255'"
      label="Name"
      autocomplete="not-my-name"
      :counter="255"
      :error-messages="errors.collect('name')"
      data-vv-name="name"
      required
    />
    <v-autocomplete-tag-type v-model="type" />
  </v-container>
</template>

<script type="text/babel">
import { createNamespacedHelpers } from "@/store/helpers";
import VAutocompleteTagType from "../tag-type/VAutocompleteTagType";
const { mapComputed } = createNamespacedHelpers("tagSubType");

export default {
  name: "tag-sub-type",
  components: { VAutocompleteTagType },
  inject: ["$validator"],
  data: () => {
    return {
      tagTypes: ["article", "keyword", "website"]
    };
  },
  computed: {
    ...mapComputed(["name", "type", "color"])
  }
};
</script>
