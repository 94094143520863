<template>
  <v-card color="white">
    <crud-header :title="name" type="TagSubType" store="tagSubType" />
    <tag-sub-type />
  </v-card>
</template>

<script type="text/babel">
import tagSubTypeModule from "@/store/modules/tag-sub-type";
import CrudHeader from "../../components/generic/crud/CrudHeader";
import { createNamespacedHelpers } from "vuex";
import { mapDetailViewLifecycleMethods } from "../helpers";
import TagSubType from "@/components/tag-sub-type/TagSubType";

const { mapGetters } = createNamespacedHelpers("tagSubType");

const detailViewLifecycleMethods = mapDetailViewLifecycleMethods(
  "tagSubType",
  tagSubTypeModule
);

export default {
  name: "tag-sub-type-view",
  $_veeValidate: {
    validator: "new" // give me my own validator scope.
  },
  components: {
    TagSubType,
    CrudHeader
  },
  data: () => {
    return {};
  },
  computed: {
    ...mapGetters(["name", "id"])
  },
  ...detailViewLifecycleMethods
};
</script>
