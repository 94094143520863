<template>
  <v-card color="white">
    <crud-header
      :title="name"
      type="Tag Sub Type"
      store="tagSubType"
      @save-success="
        ({ id }) =>
          $router.push({
            name: 'tag-sub-types.show',
            params: { tagSubTypeId: id }
          })
      "
    />
    <tag-sub-type />
  </v-card>
</template>

<script type="text/babel">
import tagSubTypeModule from "@/store/modules/tag-sub-type";
import CrudHeader from "../../components/generic/crud/CrudHeader";
import { createNamespacedHelpers } from "vuex";
import { mapCreateViewLifecycleMethods } from "../helpers";
import TagSubType from "@/components/tag-sub-type/TagSubType";

const { mapGetters } = createNamespacedHelpers("tagSubType");

const createViewLifecycleMethods = mapCreateViewLifecycleMethods(
  "tagSubType",
  tagSubTypeModule
);

export default {
  name: "tag-sub-type-create-view",
  $_veeValidate: {
    validator: "new" // give me my own validator scope.
  },
  components: {
    TagSubType,
    CrudHeader
  },
  computed: {
    ...mapGetters(["name", "type"])
  },
  ...createViewLifecycleMethods
};
</script>
